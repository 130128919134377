<template>
  <div class="main_page">
    <div style="
        box-shadow: 0px 2px 5px rgb(0 0 0 / 35%);
        background: #fff;
        padding: 10px 30px;
        height: 100%;
        box-sizing: border-box;
      ">


      <div class="input_title">
        <div>{{ language.WalletInformation }}</div>
        <div class="addbox">
          <el-button size="small" style="width: 100px" type="primary" @click="add()">+{{ language.addto }}</el-button>
        </div>
      </div>

      <div>
        <!-- 银行列表 -->
        <div style="margin-top: 10px">
          <el-table v-if="radio1show" v-loading="loading" :data="tableData" border style="width: 100%" stripe
            :header-cell-style="{ background: '#fafafa' }">
            <!-- 用户名 -->
            <el-table-column prop="customer_name" :label="language.UserName" min-width="10%">
            </el-table-column>
            <!-- 币种 -->
            <el-table-column prop="currency" :label="language.Currency" min-width="10%">
            </el-table-column>
            <!-- 开户账号/钱包地址 -->
            <el-table-column prop="wallet_address" :label="language.Walletaddress" min-width="10%">
            </el-table-column>
            <!-- wallet_address -->

            <el-table-column fixed="right" :label="language.Operation" min-width="10%">
              <template slot-scope="scope">
                <el-button size="mini" type="danger" @click="Deletebtn(scope.row)">{{
                  language.Delete
                }}</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div>
          <div class="paging">
            <el-pagination v-loading="loading" @size-change="handleSizeChange" @current-change="handleCurrentChange"
              current-page.sync="5" :page-sizes="[10, 15, 20, 25, 30]" :page-size="limit"
              layout="sizes, prev, pager, next" :total="pages">
            </el-pagination>
          </div>
        </div>
        <!-- 添加银行卡 -->
        <el-dialog :title="language.Addwalletaddress" :visible.sync="addshow" width="800px"
          :close-on-click-modal="false">
          <div id="addbox" style="">
            <div style="
                display: flex;
                flex-direction: column;
                align-items: center;
                padding-bottom: 40px;
                border: 1px solid #efefef;
                border-top: none;
              ">
              <div>
                <!-- 用户名 -->
                <div style="
                    position: relative;
                    display: flex;
                    align-items: center;
                    margin-top: 20px;
                  ">
                  <div :style="languagetype == 'EN'
                    ? 'min-width: 170px;white-space: nowrap'
                    : 'min-width: 100px;white-space: nowrap'
                    ">
                    {{ language.UserName }}:
                  </div>
                  <!-- <el-input style="" v-model="Old_password" placeholder=""></el-input> -->
                  <el-input style="box-sizing: border-box" v-model="UserName" type="text" placeholder=""></el-input>
                </div>
                <div style="
                    position: relative;
                    display: flex;
                    align-items: center;
                    margin-top: 20px;
                  ">
                  <div :style="languagetype == 'EN'
                    ? 'min-width: 170px;white-space: nowrap'
                    : 'min-width: 100px;white-space: nowrap'
                    ">
                    {{ language.bindingaddress }}:
                  </div>
                  <!-- <el-input style="" v-model="Old_password" placeholder=""></el-input> -->
                  <el-input style="box-sizing: border-box" v-model.trim="bindingaddress" type="text"
                    placeholder=""></el-input>
                </div>
                <!-- 币种 -->
                <div style="
                    position: relative;
                    display: flex;
                    align-items: center;
                    margin-top: 20px;
                  ">
                  <div :style="languagetype == 'EN'
                    ? 'min-width: 170px;white-space: nowrap'
                    : 'min-width: 100px;white-space: nowrap'
                    ">
                    {{ language.Currency }}:
                  </div>
                  <!-- <el-input style="" v-model="Old_password" placeholder=""></el-input> -->
                  <el-select v-model="Currency" :placeholder="language.Currency">
                    <el-option v-for="item in currencylist" :key="item.currency" :label="item.currency"
                      :value="item.currency">
                      <span style="float: left">{{ item.currency }}</span>
                      <span style="float: right; color: #8492a6;">{{ item.country }}</span>
                    </el-option>
                  </el-select>
                </div>

                <!-- 验证码 -->
                <div style="
                    position: relative;
                    display: flex;
                    align-items: center;
                    margin-top: 20px;
                  ">
                  <div :style="languagetype == 'EN'
                    ? 'min-width: 170px;white-space: nowrap'
                    : 'min-width: 100px;white-space: nowrap'
                    ">
                    {{ language.GoogleVerificationCode }}:
                  </div>
                  <!-- <el-input style="" v-model="Old_password" placeholder=""></el-input> -->
                  <el-input style="box-sizing: border-box" v-model="VerificationCode" type="text"
                    placeholder=""></el-input>
                  <!-- white-space: nowrap; -->
                </div>
                <div style="display: flex; justify-content: center; margin-top: 20px">
                  <el-button type="primary" @click="Addwalletaddress()">{{
                    language.determine
                  }}</el-button>
                </div>
              </div>
            </div>
          </div>
        </el-dialog>
      </div>
    </div>
  </div>
</template>

<script>
// import payment from "../../../common/json/payment.json";
import {
  wallet,
  walletadd,
  walletdelete,
} from "@/api/index";
export default {
  data() {
    return {
      language: "",
      languagetype: null,
      tableData: [],
      loading: false,
      pages: 1,
      page: 1,
      limit: 15,
      addshow: false,
      mobile_number: "",
      VerificationCode: "",
      Bankcardnumber: "",
      Bankcode: "",
      Cardholder: "",
      UserName: "",
      bindingaddress: "",
      currencylist: [
        {
          currency: "INR",
          country: "印度"
        }
      ],
      Currency: "",
      time2: "",
      sendtimeInterval: "",
      info: "",
      radio1show: true,
      transferFromId: "",
      list: ""
    };
  },
  created() {
    this.info = JSON.parse(localStorage.getItem("info"));
    this.changelanguage();
    this.init();
  },
  watch: {
    "$store.state.language"() {
      this.changelanguage();
    },
  },
  methods: {
    // 删除虚拟钱包
    Deletebtn(e) {
      //删除虚拟钱包
      let data = {
        id: e.id,
      };
      this.$confirm(this.language.confirmdeletion, this.language.prompt, {
        confirmButtonText: this.language.determine,
        cancelButtonText: this.language.cancel,
        type: "warning",
      })
        .then(() => {
          walletdelete(data, data).then((res) => {
            if (res.code == 1) {
              this.$message({
                type: "success",
                message: this.language.Edit_succeeded + "!",
              });
              this.init();
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: this.language.Cancelleddeletion,
          });
        });

    },
    init() {

      wallet().then((res) => {
        this.tableData = res.data.list;
      });

    },
    Addwalletaddress() {
      if (!this.UserName) {
        this.$message({
          message: this.language.UserName + this.language.cannotbeempty,
          type: "warning",
        });
        return;
      }
      if (!this.bindingaddress) {
        this.$message({
          message: this.language.bindingaddress + this.language.cannotbeempty,
          type: "warning",
        });
        return;
      }
      if (!this.VerificationCode) {
        this.$message({
          message: this.language.VerificationCode + this.language.cannotbeempty,
          type: "warning",
        });
        return;
      }
      let data = {
        customer_name: this.UserName,
        wallet_address: this.bindingaddress,
        currency: this.Currency,
        captcha: this.VerificationCode,
      };
      walletadd(data).then((res) => {
        if (res.code == 1) {
          this.$message({
            message: this.language.Edit_succeeded,
            type: "success",
          });
          this.init();
          this.addshow = false;
        }
      });
    },
    add() {
      this.Cardholder = "";
      this.Bankcardnumber = "";
      this.Bankcode = "";
      // this.Currency = "";
      this.VerificationCode = "";
      this.UserName = "";
      this.bindingaddress = "";
      this.addshow = true;
    },
    // 切换每页条数
    handleSizeChange(val) {
      this.page = 1;
      this.limit = val;
      this.init();
    },
    //选择页数
    handleCurrentChange(val) {
      this.page = val;
      this.init();
    },
    // 切换语言
    changelanguage() {
      this.languagetype = this.$store.state.language;
      this.language = this.$units();
    },
  },
};
</script>
<style scoped>
.main_page {
  min-height: 100%;
  box-sizing: border-box;
}

.page_title {
  font-size: 26px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.input_title {
  font-size: 20px;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px dashed rgb(203, 203, 203);
}

.addbox {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding-bottom: 4px;
}

.paging {
  width: 100%;
  display: flex;
  margin-top: 30px;
  justify-content: flex-end;
}

.main_page>>>.el-tabs__header {
  margin: 0;
}
</style>
