<template>
  <div class="administration_page">
    <div style="
        box-shadow: 0px 2px 5px rgb(0 0 0 / 35%);
        background: #fff;
        padding: 10px 30px;
        height: 100%;
        box-sizing: border-box;
      ">
      <!-- 添加商户 -->
      <div class="input_title">
        <div>{{ language.Application_Management }}</div>
        <div class="addbox" v-if="rules.mchappsave">
          <el-button size="small" style="width: 100px" type="primary" @click="add()">+{{ language.addto }}</el-button>
        </div>
      </div>

      <div>
        <!-- 商户列表 -->
        <div style="margin-top: 30px">
          <el-table v-loading="loading" :data="tableData" border style="width: 100%" stripe
            :header-cell-style="{ background: '#fafafa' }">
            <!-- Apply名 -->
            <el-table-column prop="name" :label="language.Apply_Name" min-width="10%">
            </el-table-column>
            <!-- Applyid -->
            <el-table-column prop="appId" :label="language.Apply_No + '/appId'" min-width="10%">
            </el-table-column>
            <!-- IP -->
            <el-table-column prop="ip_whitelist" label="IP" min-width="10%">
            </el-table-column>

            <!-- 应用密钥 -->
            <el-table-column prop="appSecret" :label="language.ApplicationKey" min-width="20%">
              <template slot-scope="scope">
                <div style="display: flex;align-items: center;justify-content: space-around;">

                  <div style="width:250px">{{ scope.row.showAppSecret ? scope.row.appSecret :
                    "************" }}
                  </div>
                  <el-button v-if="!scope.row.showAppSecret" size="mini" type="primary" plain
                    @click="checkText(scope.row.id)">{{ language.lookover }}</el-button>
                  <el-button v-if="scope.row.showAppSecret" style="margin-left:0" size="mini" type="success" plain
                    @click="copyText(scope.row.appSecret ? scope.row.appSecret : '')">{{ language.copy }}</el-button>
                </div>

              </template>
            </el-table-column>


            <!-- 使用场景 -->
            <el-table-column prop="scene" :label="language.Use_Scenarios" min-width="10%">
              <template slot-scope="scope">
                <span v-if="scope.row.scene == 0">{{ language.Mobilewebpage }}</span>
                <span v-if="scope.row.scene == 1">{{ language.officialaccount }}</span>
                <span v-if="scope.row.scene == 2">{{ language.Applets }}</span>
                <span v-if="scope.row.scene == 3">{{ language.Computerwebsite }}</span>
              </template>
            </el-table-column>
            <!-- 创建时间 -->
            <el-table-column prop="create_time" :label="language.Creation_time" min-width="10%">
            </el-table-column>

            <el-table-column v-if="rules.mchappread || rules.mchappupdate" fixed="right" :label="language.Operation"
              min-width="10%">
              <template slot-scope="scope">
                <!-- 详情 -->
                <!-- <el-button v-if="rules.mchappread" @click="handleClickshow(scope.row)" type="text" size="small">{{
                  language.ViewKey }}</el-button> -->
                <!-- 修改 -->
                <el-button v-if="rules.mchappupdate" @click="handleClickchange(scope.row)" type="text" size="small">{{
                  language.Modify_information }}</el-button>
                <!-- 删除 -->
                <el-button v-if="rules.mchappupdate" @click="handleClickdelete(scope.row)" type="text" size="small">{{
                  language.Delete }}</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div>
          <div class="paging">
            <el-pagination v-loading="loading" @size-change="handleSizeChange" @current-change="handleCurrentChange"
              current-page.sync="5" :page-sizes="[10, 15, 20, 25, 30]" :page-size="limit"
              layout="sizes, prev, pager, next" :total="pages">
            </el-pagination>
          </div>
        </div>
      </div>
      <el-dialog :title="title" :visible.sync="View_detailsshow" width="80%" center v-loading="loading2"
        :close-on-click-modal="false">
        <div style="
            display: flex;
            flex-direction: column;
            align-items: center;
            padding-bottom: 40px;
          ">
          <!-- 应用名称 -->
          <div style="display: flex; width: 100%">
            <div style="margin-top: 20px; width: 30%; margin-right: 5%">
              <div>{{ language.Apply_Name }}</div>
              <el-input style="border: none; margin-top: 10px" :disabled="!truebtn" v-model="Apply_Name"
                placeholder=""></el-input>
            </div>
            <!-- 加密方式 -->
            <div style="margin-top: 20px; width: 30%; margin-right: 5%" v-if="btntype == 1">
              <div>{{ language.Encryptionmode }}</div>
              <el-select v-model="Encryptionmode" :disabled="!truebtn" style="width: 100%; margin-top: 10px">
                <el-option v-for="item in Encryptionmodelist" :key="item.value" :label="item.label" :value="item.value"
                  :disabled="item.disabled">
                </el-option>
              </el-select>
            </div>
            <!-- 使用场景 -->
            <div style="margin-top: 20px; width: 30%">
              <div>{{ language.Use_Scenarios }}</div>
              <el-input style="border: none; margin-top: 10px" disabled v-model="Use_Scenarios"
                placeholder=""></el-input>
            </div>
          </div>
          <!-- 应用密匙 -->
          <div style="margin-top: 20px; width: 100%" v-if="!truebtn">
            <div>{{ language.ApplicationKey }}</div>
            <div style="display: flex; margin-top: 20px; align-items: center">
              <div style="display: flex; align-items: center">
                <div v-if="ViewKeytrue">******</div>
                <div v-if="!ViewKeytrue">{{ ViewKeymsg }}</div>
                <el-button style="margin-left: 10px" v-if="!ViewKeytrue" plain size="mini" type="primary"
                  @click="copy()">{{
                    language.copy }}</el-button>
              </div>
              <!-- 查看密匙  ApplicationKey -->
              <div style="margin-left: 20px" v-if="ViewKeytrue && rules.mchappreadAppSecret">
                <el-button plain size="mini" type="primary" @click="ViewKey()">{{
                  language.ViewKey
                }}</el-button>
              </div>
            </div>
          </div>

          <!-- 应用简介 -->
          <div style="margin-top: 20px; width: 100%">
            <div>{{ language.ApplicationIntroduction }}</div>
            <el-input type="textarea" :disabled="!truebtn" :autosize="{ minRows: 12, maxRows: 12 }"
              style="margin-top: 10px" placeholder="" v-model="ApplicationIntroduction">
            </el-input>
          </div>
          <!-- ip -->
          <div v-if="!truebtn" style="margin-top: 20px; width: 100%">
            <div>IP</div>
            <el-input type="textarea" disabled :autosize="{ minRows: 5, maxRows: 5 }" style="margin-top: 10px"
              placeholder="" v-model="IP">
            </el-input>
          </div>
          <div style="margin-top: 50px" v-if="truebtn">
            <el-button type="primary" @click="btn()">{{ title }}</el-button>
          </div>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import rules from "@/common/js/rules";
// import axios from "axios";
import { app, appsave, appread, appupdate, appdelete, appreadAppSecret } from "@/api/index.js";
export default {
  data() {
    return {
      loading: true,
      loading2: true,
      title: "",
      View_detailsshow: false, //详情展示
      language: "",
      tableData: [],
      Encryptionmodelist: [
        {
          value: 1,
          label: "MD5",
          // }, {
          //     value: 2,
          //     // disabled: true,
          //     label: 'RSA2'
        },
      ], //加密方式
      Apply_Name: "", //应用名称
      Encryptionmode: null, //加密方式
      Use_Scenarios: "", //使用场景
      ApplicationIntroduction: "", //应用简介
      truebtn: true, //是否展示按钮
      page: 1, //页数
      showlimit: 10,
      limit: 15,
      pages: 0,
      payTypes: "",
      btntype: 1, //1为添加  2为修改
      id: 0,
      ViewKeytrue: true,
      ViewKeymsg: "",
      rules: "",
      IP: "",
    };
  },
  created() {
    // 判断语言
    this.changelanguage();
    this.init();
  },
  watch: {
    // 判断语言
    "$store.state.language"() {
      this.changelanguage();
    },
  },
  mounted() {
    this.rules = rules.rules().msg;
  },

  methods: {

    // 查看密钥
    checkText(id) {
      this.$prompt(this.language.Loginpassword, this.language.Systemprompt, {
        confirmButtonText: this.language.determine,
        cancelButtonText: this.language.Cancel,
      })
        .then(({ value }) => {
          //  查看请求
          let data = {
            loginPassword: value,
          };
          appreadAppSecret(data, { id }).then((res) => {
            if (res.code == 1) {
              this.tableData.map(item => {
                this.$set(item, "showAppSecret", false)
                item.appSecret = ""
                if (item.id == res.data.id) {
                  this.$set(item, "showAppSecret", true)
                  item.appSecret = res.data.appSecret
                }
              })
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: this.language.Canceled,
          });
        });
    },
    // 复制
    async copyText(text) {
      try {
        this.$copyText(text);
        this.$message.success("已复制到剪切板");
      } catch {
        this.$message.error("复制失败");
      }
    },
    // 获取列表数据
    init() {
      this.loading = true;
      let data = {
        page: this.page,
        limit: this.limit,
      };
      app({}, data).then((res) => {
        if (res.code == 1) {
          this.loading = false;
          res.data.list.map((item) => {
            item.ip_whitelist = item.ip_whitelist.replace(/,/g, "、");
          });
          this.tableData = res.data.list;
          this.pages = res.data.count;
        }
      });
    },
    // 切换每页条数
    handleSizeChange(val) {
      this.page = 1;
      this.limit = val;
      this.init();
    },
    // 选择页数
    handleCurrentChange(val) {
      this.page = val;
      this.init();
    },

    // 获取语言
    changelanguage() {
      this.language = this.$units();
      this.Use_Scenarios = this.language.Mobilewebpage;
    },
    // 添加商户
    add() {
      this.btntype = 1;
      this.title = this.language.addto; //弹窗顶部
      this.View_detailsshow = true; //展示弹窗
      this.truebtn = true; //显示按钮
      this.Apply_Name = "";
      this.Encryptionmode = "";
      this.ApplicationIntroduction = "";
      this.Use_Scenarios = this.language.Mobilewebpage;
    },
    // 修改查询
    handleClickchange(e) {
      this.id = e.id;
      let data = {
        id: e.id,
      };
      this.loading = true;
      appread({}, data).then((res) => {
        this.loading = false;
        if (res.code == 1) {
          this.btntype = 2;
          this.title = this.language.Modify_information; //弹窗顶部
          this.View_detailsshow = true; //展示弹窗
          this.truebtn = true; //显示按钮
          this.Apply_Name = res.data.name;
          this.Use_Scenarios = this.language.Mobilewebpage;
          this.ApplicationIntroduction = res.data.description;
          this.payTypes = res.data.payTypes;
          this.Encryptionmode = res.data.encrypt + 1;
        }
      });
    },
    // 删除
    handleClickdelete(e) {
      this.$confirm(this.language.confirmdeletion, this.language.prompt, {
        confirmButtonText: this.language.determine,
        cancelButtonText: this.language.cancel,
        type: "warning",
      })
        .then(() => {
          let data = {
            id: e.id,
          };
          appdelete(data, {}).then((res) => {
            if (res.code == 1) {
              this.$message({
                type: "success",
                message: this.language.Submittedsuccessfully + "!",
              });
              this.init();
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: this.language.Cancelleddeletion,
          });
        });
    },
    // 确认添加或修改
    btn() {
      if (!this.Apply_Name) {
        this.$message({
          message: this.language.noApply_Name,
          type: "warning",
        });
        return;
      }
      // if (!this.Use_Scenarios) {
      //     this.$message({
      //         message: this.language.noUse_Scenarios,
      //         type: 'warning'
      //     });
      //     return
      // }
      if (!this.ApplicationIntroduction) {
        this.$message({
          message: this.language.noApplicationIntroduction,
          type: "warning",
        });
        return;
      }
      let data;
      if (this.btntype == 1) {
        if (!this.Encryptionmode) {
          this.$message({
            message: this.language.noEncryptionmode,
            type: "warning",
          });
          return;
        }
        data = {
          name: this.Apply_Name,
          encrypt: this.Encryptionmode - 1,
          scene: 0,
          description: this.ApplicationIntroduction,
          // Use_Scenarios: this.Use_Scenarios
        };

        appsave(data).then((res) => {
          if (res.code == 1) {
            this.init();
            this.$message({
              message: this.language.Edit_succeeded,
              type: "success",
            });
            this.View_detailsshow = false; //隐藏弹窗
          }
        });
      } else if (this.btntype == 2) {
        data = {
          name: this.Apply_Name,
          payTypes: this.payTypes,
          description: this.ApplicationIntroduction,
          // Use_Scenarios: this.Use_Scenarios
        };
        appupdate(data, { id: this.id }).then((res) => {
          if (res.code == 1) {
            this.init();
            this.$message({
              message: this.language.Edit_succeeded,
              type: "success",
            });
            this.View_detailsshow = false; //隐藏弹窗
          }
        });
      }
    },
  },
};
</script>
<style scoped>
.administration_page {
  height: 100%;
  box-sizing: border-box;
}

.page_title {
  font-size: 26px;
  font-weight: 600;
}

.input_title {
  font-size: 20px;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px dashed rgb(203, 203, 203);
}

.addbox {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding-bottom: 4px;
}

.paging {
  width: 100%;
  display: flex;
  margin-top: 30px;
  justify-content: flex-end;
}

.administration_page /deep/ .el-input .el-input__inner {
  padding: 0 4px !important;
}

.administration_page /deep/ .el-table .cell {
  white-space: nowrap;
}
</style>
